import React from "react";
import {useTranslation} from "react-i18next";
import useComponentTranslations from "../../utils/translation/useComponentTranslations";
import enUS from "./locales/en-us.json";
import ptBR from "./locales/pt-br.json";
import esES from "./locales/es-es.json";
import ptPT from "./locales/pt-pt.json";

const SidePanel = ({className, children}) => {
    const {t, i18n} = useTranslation()
    useComponentTranslations('loginForm', {"en-US": enUS, "pt-BR": ptBR, "es-ES": esES, "pt-PT": ptPT});
    return (
        <div className={`hidden md:block row-span-3 h-screen ${className}`}>
            {children}
            <div className={'fixed bottom-0 left-0 p-2'}>
                <span className={'text-white-light'}>
                    {t("loginForm:appDownloadDescription")}
                </span>
                <div className="flex place-items-center space-x-2 mt-1">
                    <a
                        rel="noreferrer"
                        target={"_blank"}
                        href="https://apps.apple.com/us/app/mfit-personal/id1283273690?itsct=apps_box_badge&amp;itscg=30200"
                    >
                        <img
                            src={`https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/${i18n.language}`}
                            alt="Download on the App Store"
                            className={"rounded w-auto h-[40px]"}
                        />
                    </a>
                    <a rel="noreferrer"
                       target={"_blank"}
                       href='https://play.google.com/store/apps/details?id=app.mfit.personal&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img
                            alt='Get it on Google Play'
                            className={"rounded w-auto h-[56px]"}
                            src={`https://play.google.com/intl/en_us/badges/static/images/badges/${i18n.language.split("-")[0]}_badge_web_generic.png`}/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default SidePanel;