import React, { useEffect } from 'react';

const withViewportControl = (WrappedComponent) => {
    const disableZoom = () => {
        const viewport = document.querySelector('meta[name="viewport"]');
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');
    };

    const enableZoom = () => {
        const viewport = document.querySelector('meta[name="viewport"]');
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes');
    };

    return (props) => {
        useEffect(() => {
            const handleFocus = (e) => {
                if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
                    disableZoom();
                }
            };

            const handleBlur = (e) => {
                if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
                    enableZoom();
                }
            };

            window.addEventListener('focus', handleFocus, true);
            window.addEventListener('blur', handleBlur, true);

            return () => {
                window.removeEventListener('focus', handleFocus, true);
                window.removeEventListener('blur', handleBlur, true);
            };
        }, []);

        return <WrappedComponent {...props} />;
    };
};

export default withViewportControl;
