import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useComponentTranslations from "../../utils/translation/useComponentTranslations";
import enUS from "./locales/en-us.json";
import ptBR from "./locales/pt-br.json";
import esES from "./locales/es-es.json";
import ptPT from "./locales/pt-pt.json";
import {getLoading} from "../../store/authentication/authenticationSelector";
import {recoverPassword} from "../../store/authentication/authenticationReducer";
import {Button, ErrorMessageInput} from "mfit-components";
import React from "react";

const PasswordRecoveryForm = ({type}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const loading = useSelector(getLoading);
    useComponentTranslations('loginForm', {"en-US": enUS, "pt-BR": ptBR, "es-ES": esES, "pt-PT": ptPT});

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(`${t("loginForm:invalidEmail")}`)
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, `${t("loginForm:invalidEmail")}`)
            .required(`${t("loginForm:requiredEmail")}`),
    });

    const initialValues = {
        email: ''
    };
    const handleSubmit = (email) => {
        dispatch(recoverPassword({email, type}));
    }

    return (
        <div className={'flex flex-col justify-center content-center p-2'}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values) => {
                    handleSubmit(values.email)
                }}
            >
                {({ errors, touched}) => (
                    <Form>
                        <Field name="email" className={"custom-form"} type="email" placeholder={t("loginForm:placeHolderEmail")}/>
                        {errors.email && touched.email &&
                            <ErrorMessageInput className={'text-red-danger-500'}>{errors.email}</ErrorMessageInput>}
                        <div className="pb-4 pt-2">
                            <Button disabled={loading}
                                    size={"btn"}
                                    variant={type === "user" ? "default" : "primary"}
                                    type="submit">
                                {loading ? t('loginForm:waiting') : t('loginForm:recover')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
};

export default PasswordRecoveryForm;
