import logger from 'redux-logger';
import {configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import {rootReducer} from "./rootReducer";
import {rootSaga} from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();
const middleWares = [
    process.env.NODE_ENV !== 'production' && logger,
    sagaMiddleware,
].filter(Boolean);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(middleWares)
})


sagaMiddleware.run(rootSaga);