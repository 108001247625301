import {createSlice} from '@reduxjs/toolkit';

const INITIAL_STATE = {
    loading: false,
    user: null,
    version: 0,
    error: 0,
    authentication: 0
}

export const slice = createSlice({
    name: "user",
    initialState: INITIAL_STATE,
    reducers: {
        setVersion(state, action) {
            state.version = action.payload
        },
        setUser(state, action) {
            state.user = action.payload;
        },
        create(state) {
            state.loading = true
        },
        setError(state, action) {
            state.error = action.payload;
            state.loading = false
        },
        onError(state) {
            state.loading = false
        }
    }
})

export const {setUser, create, onError, setVersion, setError} = slice.actions;
export const userReducer = slice.reducer;