import {createSlice} from "@reduxjs/toolkit";

export const INITIAL_STATE = {
    toasts: []
};

export const toastSlice = createSlice(
    {
        name: 'toasts',
        initialState: INITIAL_STATE,
        reducers: {
            addToast(state, action) {

                state.toasts = [action.payload || {}]
            },
            removeToast(state, action) {
                state.toasts = state.toasts.filter(toast => toast.id !== action.payload)
            }
        }
    }
);

export const {addToast, removeToast} = toastSlice.actions;
export const toastReducer = toastSlice.reducer;
