import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function useComponentTranslations(namespace, translations) {
    const { i18n } = useTranslation();
    const [loaded, setLoaded] = useState(false); // new state

    useEffect(() => {
        const languages = Object.keys(translations);
        Promise.all(
            languages.map(lng =>
                i18n.addResourceBundle(lng, namespace, translations[lng], true, true)
            )
        ).then(() => setLoaded(true));
    }, [i18n, namespace, translations]);

    return   loaded ;
}

export default useComponentTranslations;
