import PasswordRecoveryForm from "../../components/login/PasswordRecoveryForm";
import {useNavigate, useParams} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import useComponentTranslations from "../../utils/translation/useComponentTranslations";
import enUS from "./locales/en-us.json";
import ptBR from "./locales/pt-br.json";
import esES from "./locales/es-es.json";
import ptPT from "./locales/pt-pt.json";
import {useDispatch, useSelector} from "react-redux";
import {getPasswordRecovered} from "../../store/authentication/authenticationSelector";
import {setRecoveredPassword} from "../../store/authentication/authenticationReducer";
import SidePanel from "../../components/side/SidePanel";
import {Button, WarningMessage} from "mfit-components";
import primaryTop from "./../../assets/img/primaryTop.png";
import defaultTop from "./../../assets/img/defaultTop.png";
import primaryBottom from "./../../assets/img/primaryBottom.png";
import defaultBottom from "./../../assets/img/defaultBottom.png";
import userFemale1 from "./../../assets/img/userFemale1.jpeg"
import clientFemale1 from "../../assets/img/clientFemale1.jpg";

const RecoverPassword = () => {
    const {type} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const passwordRecovered = useSelector(getPasswordRecovered);
    useComponentTranslations('recover', {"en-US": enUS, "pt-BR": ptBR, "es-ES": esES, "pt-PT": ptPT});
    const handleGoBack = () => {
        dispatch(setRecoveredPassword(0))
        navigate(`/${type}`)
    }
    const handleTryAgain = () => {
        dispatch(setRecoveredPassword(0))
    }

    return (
        <div className={'grid xl:grid-cols-2 h-dvh items-center'}>
            <div className="hidden xl:block">
                <SidePanel>
                    <img
                        className={"w-full h-full object-cover"}
                        src={type === 'user' ? userFemale1 : clientFemale1}
                        alt={type === 'user' ? 'user female' : 'client female'}
                    />
                </SidePanel>
            </div>
            <div className={"flex flex-col items-center"}>
                <img
                    src={type === 'user' ? defaultTop : primaryTop}
                    alt={type === 'user' ? 'Default Top' : 'Primary Top'}
                    className="absolute right-0 top-0 w-min xl:hidden -z-10"
                />
                <div className={'flex flex-col items-center text-xs p-2'}>
                    <span onClick={handleGoBack} className={""}>
                        <i className={'fa-regular fa-chevron-left'}/>
                        <span className={'fw-semibold ml-1'}>
                            {t("recover:goBack")}
                        </span>
                    </span>
                </div>
                <div className={"flex flex-col items-center"}>
                    <div className={'flex flex-col items-center'}>
                        <img
                            alt={"logo"}
                            src={`https://cdn.mfitpersonal.com.br/assets/logo/logo-mfit-horizontal-azul.png`}
                            className={"max-w-80"}
                        />
                        <span className={`text-xl font-semibold text-${type === "user" ? 'default' : 'primary'}`}>
                                {t(`loginForm:${type}`)}
                            </span>
                    </div>
                    <div className={'min-w-80'}>
                        {
                            passwordRecovered === 0 &&
                            <PasswordRecoveryForm type={type}/>
                        }
                        {
                            passwordRecovered === 1 &&
                            <div className={"flex flex-col content-center justify-center"}>
                                <div className={'flex flex-col items-center justify-center gap-1 pt-2 px-4'}>
                                    <WarningMessage
                                        icon={"fa-regular fa-envelope-open-text justify-center items-center text-white-light"}
                                        color={"bg-dark"}
                                        title={t("recover:emailSent")}
                                        subtitle={t("recover:emailSentMessage")}
                                    />
                                </div>
                                <div className={'px-2 flex flex-col justify-center'}>
                                    <div className={'pt-4 px-2'}>
                                        <Button
                                            variant={type === "user" ? "default" : "primary"}
                                            size={"btn"}
                                            onClick={handleGoBack}>{t("recover:login")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            passwordRecovered === 2 &&
                            <div className={'flex flex-col items-center justify-center'}>
                                <div className={'pb-2'}>
                                    <WarningMessage
                                        icon={"fa-regular fa-circle-exclamation text-red-danger-500"}
                                        title={t("recover:cantSendEmail")}
                                        subtitle={t("recover:cantSendEmailMessage")}
                                    />
                                </div>
                                <div className={'pt-4 px-2'}>
                                    <Button
                                        variant={type === "user" ? "default" : "primary"}
                                        size={"btn"}
                                        onClick={handleTryAgain}>{t("recover:tryAgain")}
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <img
                src={type === 'user' ? defaultBottom : primaryBottom}
                alt={type === 'user' ? 'Default Bottom' : 'Primary Bottom'}
                className="fixed bottom-0 left-0 w-min xl:hidden lg:block -z-10"
            />
        </div>
    )
}
export default RecoverPassword;