import {useEffect, useState} from 'react';

function usePageVisibility() {
    const [isVisible, setIsVisible] = useState(!document.hidden);

    const onVisibilityChange = () => setIsVisible(!document.hidden);

    useEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange);
        };
    }, []);

    return isVisible;
}

export default usePageVisibility;
