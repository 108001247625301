import {Route, Routes} from "react-router-dom";
import Client from './pages/client/Client'
import User from "./pages/user/User";
import RecoverPassword from "./pages/password/RecoverPassword";
import InitialRoute from "./routes/intial/InitialRoute";
import Home from "./pages/home/Home";
import Signup from "./pages/user/Signup";
import Logout from "./pages/logout/Logout";

function App() {
    return (
            <Routes>
                <Route path="/" element={<InitialRoute/>}>
                    <Route path="/:mclid?" element={<Home/>}/>
                    <Route path="/client" element={<Client/>}/>
                    <Route path="/user" element={<User/>}/>
                    <Route path="/recover-password/:type" element={<RecoverPassword/>}/>
                    <Route path="/signup/:mclid?" element={<Signup/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                </Route>
            </Routes>
    );
}

export default App;
