import axios from "axios";
import {apiUrl} from "../api";

export const createUserAPI = async (formData, mclid) => {

    return await axios.post(`${apiUrl}/user/?mclid=${mclid}`, formData, {
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 20000
    })

}