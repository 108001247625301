import {takeLatest, all, call, put} from 'redux-saga/effects';
import {createUserAPI} from "../../api/user/userActions";
import {create, onError, setError, setUser} from "./userReducer";
import {handleError} from "../../utils/error/sagaErrorHandling";

export function* createUserAsync(action) {
    try {
        const {formData, mclid} = action.payload;
        const response = yield call(createUserAPI, formData, mclid);
        yield put(setUser(response.data));
    } catch (error) {
        console.log(error)
        console.log(error?.response?.status)
        if (error?.response?.status) {
            yield put(setError(error.response.status));
        } else
            yield call(handleError, error, onError);
    }
}

export function* onCreateUser() {
    yield takeLatest(create.type, createUserAsync)
}

export function* userSaga() {
    yield all([
        call(onCreateUser)
    ])
}