import {createSelector} from "reselect";

const reducer = state => state.authentication;

export const getAuthentication = createSelector(reducer, authentication => authentication.authentication);
export const getPasswordRecovered = createSelector(reducer, authentication => authentication.passwordRecovered);

export const getLoading = createSelector(
    [reducer],
    authentication => authentication.loading
);
export const getMclid = createSelector(
    [reducer], authentication => authentication.mclid
)