import {useNavigate} from "react-router-dom";
import './../../assets/css/custom.scss';
import useComponentTranslations from "../../utils/translation/useComponentTranslations";
import enUS from "../../components/login/locales/en-us.json";
import ptBR from "../../components/login/locales/pt-br.json";
import esES from "../../components/login/locales/es-es.json";
import ptPT from "../../components/login/locales/pt-pt.json";
import {useTranslation} from "react-i18next";
import SidePanel from "../../components/side/SidePanel";
import {Button} from "mfit-components";
import loginMale1 from './../../assets/img/loginMale1.jpg'
import primaryTop from './../../assets/img/primaryTop.png'
import defaultBottom from './../../assets/img/defaultBottom.png'

const Home = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    useComponentTranslations('loginForm', {"en-US": enUS, "pt-BR": ptBR, "es-ES": esES, "pt-PT": ptPT});

    return (
        <div className={'grid xl:grid-cols-2 h-dvh'}>
            <div className="hidden xl:block">
                <SidePanel>
                    <img
                        className={"w-full object-cover"}
                        src={loginMale1}
                        alt={"bg-male"}
                    />
                </SidePanel>
            </div>
            <div className={'flex flex-col justify-center items-center w-full'}>
                <img
                    src={primaryTop}
                    alt={"bg-mobile-top-primary"}
                    className={"absolute right-0 top-0 w-min xl:hidden -z-10 object-cover"}
                />
                <div>
                    <div className={'flex flex-col items-center'}>
                        <img
                            className={"max-w-80"}
                            alt={"logo"}
                            src={`https://cdn.mfitpersonal.com.br/assets/logo/logo-mfit-horizontal-azul.png`}
                        />
                        <span className={'font-semibold mb-3 text-center'}>
                                {t("loginForm:yourAppSlogan")}
                            </span>
                    </div>
                    <div className={'flex flex-col gap-1 p-1 md:px-48 justify-center content-center'}>
                        <Button
                            size={"btn"}
                            className={"min-w-[300px]"}
                            onClick={() => navigate("/client")}>
                            {t("loginForm:iAmClient")}
                        </Button>
                        <Button
                            size={"btn"}
                            className={"min-w-[300px]"}
                            variant={"default"}
                            onClick={() => navigate("/user")}>
                            {t("loginForm:iAmCoach")}
                        </Button>
                    </div>
                </div>
                <img
                    src={defaultBottom}
                    alt={"bg-mobile-bottom-default"}
                    className={"fixed bottom-0 left-0 w-min xl:hidden -z-10"}
                />
            </div>
        </div>
    )
}

export default Home;
