import {put} from 'redux-saga/effects';
import {addToast} from "../../store/toast/toastReducer";
import i18n from "../../i18n";
import enUS from "./locales/en-us.json";
import ptBR from "./locales/pt-br.json";
import esES from "./locales/es-es.json";
import ptPT from "./locales/pt-pt.json";

export function* handleError(error, onError) {
    i18n.addResourceBundle("pt-BR", "sagaError", ptBR);
    i18n.addResourceBundle("en-US", "sagaError", enUS);
    i18n.addResourceBundle("es-ES", "sagaError", esES);
    i18n.addResourceBundle("pt-PT", "sagaError", ptPT);
    yield put(onError());
    if (error.code === "ERR_NETWORK") {
        yield put(addToast({
            title: i18n.t('sagaError:internetErrorTitle'),
            message: i18n.t('sagaError:internetErrorMessage'),
            delay: 10000,
        }));
    } else if (error.code === "ECONNABORTED") {
        yield put(addToast({
            title: i18n.t('sagaError:internetErrorTitle'),
            message: i18n.t('sagaError:internetErrorMessage'),
            delay: 10000,
        }));
    } else if (error.response.status === 401) {
        yield put(addToast({
            title: i18n.t('sagaError:clientOrUserBlockedTitle'),
            message: i18n.t('sagaError:clientOrUserBlockedMessage'),
            delay: 10000,
            type: 'warning'
        }));
    } else
        yield put(addToast());
}

