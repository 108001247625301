import {createSelector} from "reselect";

const reducer = state => state.user;

export const getUser = createSelector(
    [reducer],
    user => user.user
);
export const getVersion = createSelector(
    [reducer],
    user => user.version
);
export const getLoading = createSelector(
    [reducer],
    user => user.loading
);

export const getError = createSelector(
    [reducer],
    user => user.error
);


