import axios from 'axios';
import {apiUrl} from "../api";

export const recoverPasswordAPI = async (email, type) => {
    if (!email) throw new Error('Por favor digite o email');
    try {
        const response = await axios.post(`${apiUrl}/recover/${type}`, {
            mail: email
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.status;
    } catch (error) {
        throw new Error(error.response?.data?.error || 'Failed to fetch auth token');
    }
}