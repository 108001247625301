import axios from "axios";
import {apiUrl} from "../api";

export const checkVersion = async () => {

    return await axios.get(`${apiUrl}/login/version/`, {
        headers: {
            'Content-Type': 'application/json'
        },
       timeout: 20000
    });
}