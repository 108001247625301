import LoginForm from "../../components/login/LoginForm";
import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import useComponentTranslations from "../../utils/translation/useComponentTranslations";
import enUS from "../../components/login/locales/en-us.json";
import ptBR from "../../components/login/locales/pt-br.json";
import esES from "../../components/login/locales/es-es.json";
import ptPT from "../../components/login/locales/pt-pt.json";
import {useTranslation} from "react-i18next";
import {getAuthentication} from "../../store/authentication/authenticationSelector";
import {setAuthentication} from "../../store/authentication/authenticationReducer";
import SidePanel from "../../components/side/SidePanel";
import {Button} from "mfit-components";
import primaryTop from './../../assets/img/primaryTop.png'
import primaryBottom from './../../assets/img/primaryBottom.png'
import clientFemale1 from "../../assets/img/clientFemale1.jpg";
import {setUpNotifications, useNotifications} from 'reapop'

const Client = () => {
    const {notify} = useNotifications()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const [showSignUpWarning, setShowSignUpWarning] = useState(false);
    const authentication = useSelector(getAuthentication);

    const urlShare = 'https://consultoria.mfitpersonal.com.br/MC9udWxsL3NoYXJlZEJ5Q2xpZW50RnJvbUFwcFNpZ251cEZvcm0vZGlyZWN0';
    useComponentTranslations('loginForm', {"en-US": enUS, "pt-BR": ptBR, "es-ES": esES, "pt-PT": ptPT});

    const handleShareLink = () => {
        if (navigator.share) {
            navigator.share({
                title: 'MFIT Personal',
                url: urlShare
            }).catch(console.error);
        } else {
            navigator.clipboard.writeText(urlShare).then(() => {
                setUpNotifications({
                    defaultProps: {
                        position: 'top-center',
                        dismissible: true,
                        dismissAfter: 5000,
                        showDismissButton: true,
                    }
                })
                notify({
                    title: t("loginForm:linkCopied"),
                    message: t("loginForm:shareTheLink"),
                    status: 'success',
                });
            });
        }
    }

    const handleGoBack = () => {
        if (showSignUpWarning) {
            setShowSignUpWarning(false)
        } else if (authentication === 2) {
            dispatch(setAuthentication(0));
        } else
            navigate("/")
    }

    return (
        <div className={'grid xl:grid-cols-2 h-dvh content-center w-full'}>
            <div className="hidden xl:block">
                <SidePanel>
                    <img
                        className={"w-full h-full object-cover"}
                        src={clientFemale1}
                        alt={"bg-female"}
                    />
                </SidePanel>
            </div>
            <div className={'flex items-center justify-center'}>
                <div className={'flex-col w-80'}>
                    <img
                        src={primaryTop}
                        alt={"bg-top-primary"}
                        className={"absolute right-0 top-0 w-min xl:hidden -z-10"}
                    />
                    <div>
                        <div className={'flex flex-col items-center text-xs'}>
                            <span onClick={handleGoBack} className={"cursor-pointer text-blue-dark"}>
                                 <i className={'fa-regular fa-chevron-left'}/>
                                <span className={'font-semibold ml-1'}> {t("loginForm:goBack")}</span>
                            </span>
                        </div>
                        <div className={'flex flex-col items-center'}>
                            <img
                                className={"max-w-80"}
                                alt={"logo"}
                                src={`https://cdn.mfitpersonal.com.br/assets/logo/logo-mfit-horizontal-azul.png`}/>
                            <span className={'text-blue-primary-400 text-xl font-bold pb-2'}>
                                {t("loginForm:client")}
                            </span>
                        </div>
                    </div>
                    <>
                        {showSignUpWarning &&
                            <div className={"flex flex-col text-center px-5"}>
                            <span className={"font-semibold pb-2"}>
                                {t("loginForm:clientMessage1")}
                            </span>
                                <span>
                                {t("loginForm:clientMessage2")}
                            </span>
                                <span aria-label={"Compartilhar"}>
                                <div className={"pt-2"}>
                                    <Button
                                        size={"md"}
                                        onClick={handleShareLink}>
                                        {t("loginForm:share")}
                                    </Button>
                                </div>
                            </span>
                            </div>
                        }
                        {
                            !showSignUpWarning &&
                            <LoginForm
                                type={"client"}
                                setShowSignUpWarning={setShowSignUpWarning}
                            />
                        }
                    </>
                </div>
                <img
                    src={primaryBottom}
                    alt={"bg-blue-primary"}
                    className={"fixed bottom-0 left-0 w-min xl:hidden -z-10"}
                />
            </div>
        </div>
    )
}

export default Client;