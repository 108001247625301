import {apiUrl} from "../api";
import axios from "axios";

export const authenticateAPI = async (email, password, type) => {
    if (!email || !password) return;

    const response = await axios.post(`${apiUrl}/auth/${type}`, {
        mail: email,
        senha: password
    })
    return response.data;
}

