import {takeLatest, all, call, put} from 'redux-saga/effects';
import {authenticateAPI} from "../../api/authentication/authentication";
import {getMainDomain} from "../../utils/cookie/cookieUtils";
import Cookies from "js-cookie";
import {handleError} from "../../utils/error/sagaErrorHandling";
import {authenticate, onError, setAuthentication, setRecoveredPassword, recoverPassword} from "./authenticationReducer";
import {recoverPasswordAPI} from "../../api/password-recovery/passwordRecovery";

export function* authenticateAsync(action) {
    try {
        const {email, password, type} = action.payload;
        const response = yield call(authenticateAPI, email, password, type);
        const mainDomain = getMainDomain();
        if (type === "client") {
            Cookies.set('client_token', response.token, {expires: 400, path: '/', domain: `.${mainDomain}`})
        } else {
            Cookies.set('user_token', response.token, {expires: 400, path: '/', domain: `.${mainDomain}`})
        }
        yield put(setAuthentication(1));
    } catch (error) {
        if (error.response.status === 403) {
            yield put(setAuthentication(2));
        } else
            yield call(handleError, error, onError);
    }
}

export function* onAuthenticate() {
    yield takeLatest(authenticate.type, authenticateAsync)
}

export function* recoverPasswordAsync(action) {
    try {
        const {email, type} = action.payload;
        yield call(recoverPasswordAPI, email, type);
        yield put(setRecoveredPassword(1))
    } catch (error) {
        yield put(setRecoveredPassword(2))
    }
}

export function* onRecoverPassword() {
    yield  takeLatest(recoverPassword.type, recoverPasswordAsync)
}

export function* authenticationSaga() {
    yield all([
        call(onAuthenticate),
        call(onRecoverPassword)
    ])
}