import {combineReducers} from "@reduxjs/toolkit";
import {toastReducer} from "./toast/toastReducer";
import {userReducer} from "./user/userReducer";
import {authenticationReducer} from "./authentication/authenticationReducer";
import {reducer as notificationsReducer} from 'reapop'

export const rootReducer = combineReducers({
    toast: toastReducer,
    user: userReducer,
    authentication: authenticationReducer,
    notifications: notificationsReducer(),
})