import {Outlet, useParams, useSearchParams} from "react-router-dom";
import {appVersion, getClientToken, getUserToken} from "../../api/api";
import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAuthentication} from "../../store/authentication/authenticationSelector";
import {getUser, getVersion} from "../../store/user/userSelector";
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import {setMclid} from "../../store/authentication/authenticationReducer";
import Cookies from "js-cookie";
import {checkVersion} from "../../api/version/versionCheck";
import moment from "moment";
import usePageVisibility from "../../utils/browser/usePageVisibility";
import {setVersion} from "../../store/user/userReducer";
import {getMainDomain} from "../../utils/cookie/cookieUtils";
import {useTranslation} from "react-i18next";
import NotificationsSystem, {wyboTheme, useNotifications} from 'reapop';

const InitialRoute = () => {
    const {notifications, dismissNotification} = useNotifications();
    const {i18n} = useTranslation();
    const userToken = getUserToken();
    const clientToken = getClientToken();
    const authentication = useSelector(getAuthentication);
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const {mclid} = useParams();
    const isVisible = usePageVisibility();
    const [searchParams] = useSearchParams();
    const version = 0; //  searchParams.get('version');
    const versionState = useSelector(getVersion)

    const returnMclid = useCallback(() => {
        if (!mclid) {
            if (isAndroid) {
                return btoa(`0/null/organic/app_signup_android`);
            } else if (isIOS) {
                return btoa(`0/null/organic/app_signup_ios`);
            } else {
                if (isMobile)
                    return btoa(`0/null/organic/app_signup_browser_mobile`);
                else
                    return btoa(`0/null/organic/app_signup_browser_desktop`);
            }
        }
        return mclid;
    }, [mclid])

    const checkVersionConst = useCallback(async () => {
        if (!Cookies.get("loginUpdateCheck")) {
            const currentVersion = await checkVersion();
            if (appVersion !== currentVersion.data.version) {
                const expirationDate = moment().add(10, 'minute');
                Cookies.set('loginUpdateCheck', 'true', {expires: expirationDate.toDate()})
                window.location.reload()
            } else {
                const expirationDate = moment().add(10, 'minute');
                Cookies.set('loginUpdateCheck', 'true', {expires: expirationDate.toDate()})
            }
        }
    }, [])

    useEffect(() => {
        if (isVisible) {
            checkVersionConst();
        }
    }, [isVisible, checkVersionConst]);

    useEffect(() => {
        if (userToken) {
            window.location.href = `https://user.mfitpersonal.com.br/redirect/${userToken}`
            // window.location.href = `https://www.mfitpersonal.com.br/extauth?token=${userToken}&version=${versionState}`;
        } else if (clientToken) {
            window.location.href = `https://client.mfitpersonal.com.br/redirect/${clientToken}/${versionState}`;
        }
    }, [userToken, clientToken, authentication, versionState, user]);

    useEffect(() => {
        if (user) {
            window.location.href = `https://www.mfitpersonal.com.br/extlogin?ml=${user.email}&cd=${user.password}`
        }
    }, [user]);

    useEffect(() => {
        const tracker = returnMclid();
        dispatch(setMclid(tracker));

    }, [mclid, returnMclid, dispatch]);

    useEffect(() => {
        if (!versionState)
            dispatch(setVersion(version))
    }, [versionState, version, dispatch])

    useEffect(() => {
        const oneSignalId = searchParams.get("onesignal_push_id");
        if (oneSignalId && oneSignalId !== "null" && oneSignalId != null) {
            const mainDomain = getMainDomain();
            Cookies.set("onesignal_push_id", searchParams.get("onesignal_push_id"), {path: '/', domain: `.${mainDomain}`})
        }
    }, [searchParams]);

    useEffect(() => {
        let lng = navigator.language;
        if (lng.includes("en")) {
            i18n.changeLanguage("en-US");
        } else if (lng.includes("es")) {
            i18n.changeLanguage("es-ES");
        } else if (lng.includes("pt-PT")) {
            i18n.changeLanguage("pt-PT");
        }
    }, [i18n])
    if (userToken || clientToken)
        return null;

    return (
        <>
            <Outlet/>
            <NotificationsSystem
                notifications={notifications}
                dismissNotification={(id) => dismissNotification(id)}
                theme={wyboTheme}
            />
        </>
    )
}

export default InitialRoute;
