import LoginForm from "../../components/login/LoginForm";
import useComponentTranslations from "../../utils/translation/useComponentTranslations";
import enUS from "../../components/login/locales/en-us.json";
import ptBR from "../../components/login/locales/pt-br.json";
import esES from "../../components/login/locales/es-es.json";
import ptPT from "../../components/login/locales/pt-pt.json";
import {useNavigate} from 'react-router-dom';
import React from "react";
import SidePanel from "../../components/side/SidePanel";
import {useTranslation} from "react-i18next";
import userFemale1 from "./../../assets/img/userFemale1.jpeg"
import defaultTop from "./../../assets/img/defaultTop.png"
import defaultBottom from "./../../assets/img/defaultBottom.png"

const User = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    useComponentTranslations('loginForm', {"en-US": enUS, "pt-BR": ptBR, "es-ES": esES, "pt-PT": ptPT});

    const handleGoBack = () => {
        navigate("/")
    }

    return (
        <>
            <div className={'grid xl:grid-cols-2 h-dvh content-center w-full '}>
                <div className={"hidden xl:block"}>
                    <SidePanel>
                        <img
                            className={"object-cover h-full w-full"}
                            src={userFemale1}
                            alt={"bg-user-female-1"}
                        />
                    </SidePanel>
                </div>
                <div className={'flex items-center justify-center'}>
                    <div className={'flex-col w-80'}>
                        <img
                            src={defaultTop}
                            alt={"bg-mobile-top-default"}
                            className={"absolute right-0 top-0 w-min xl:hidden -z-10"}
                        />
                        <div className={'w-full content-center'}>
                            <div className={'flex flex-col items-center text-xs'}>
                                <span onClick={handleGoBack} className={"cursor-pointer text-blue-dark"}>
                                     <i className={'fa-regular fa-chevron-left'}/>
                                    <span className={'font-semibold ml-1'}> {t("loginForm:goBack")}</span>
                                </span>
                            </div>
                            <div className={'flex flex-col'}>
                                <img
                                    alt={"logo"}
                                    className={'max-w-80'}
                                    src={`https://cdn.mfitpersonal.com.br/assets/logo/logo-mfit-horizontal-azul.png`}/>
                                <span className={'text-xl font-semibold text-center pb-2'}>
                                {t("loginForm:user")}
                            </span>
                                <LoginForm type={"user"}/>
                            </div>
                        </div>
                    </div>
                    <img
                        src={defaultBottom}
                        alt={'bg-mobile-bottom-default'}
                        className={"fixed bottom-0 left-0 w-min xl:hidden -z-10"}
                    />
                </div>
            </div>
        </>
    )
}

export default User;