import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {create, setError} from "../../store/user/userReducer";
import {getLoading, getMclid} from "../../store/authentication/authenticationSelector";
import useComponentTranslations from "../../utils/translation/useComponentTranslations";
import enUS from "./locales/en-us.json";
import ptBR from "./locales/pt-br.json";
import esES from "./locales/es-es.json";
import ptPT from "./locales/pt-pt.json";
import {useTranslation} from "react-i18next";
import SidePanel from "../../components/side/SidePanel";
import {getError} from "../../store/user/userSelector";
import userFemale1 from "./../../assets/img/userFemale1.jpeg";
import defaultTop from "../../assets/img/defaultTop.png";
import {Button, ErrorMessageInput, PasswordInput, WarningMessage} from "mfit-components";
import defaultBottom from "../../assets/img/defaultBottom.png";
import 'react-international-phone/style.css';
import {PhoneInput} from 'react-international-phone';
import {isValidPhoneNumber} from "libphonenumber-js";

const Signup = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const mclid = useSelector(getMclid);
    const loading = useSelector(getLoading);
    const dispatch = useDispatch();
    const error = useSelector(getError);
    useComponentTranslations('signup', {"en-US": enUS, "pt-BR": ptBR, "es-ES": esES, "pt-PT": ptPT});
    const userLanguage = navigator.language
    const userLanguageFormated = userLanguage.split("-")[1]?.toLowerCase() || userLanguage;

    const validationSchema = Yup.object().shape({
        fullName: Yup.string()
            .required(`${t("signup:fullNameIsRequired")}`)
            .test('two-names', `${t("signup:atLeastTwoNames")}`, value => value && value.split(' ').length >= 2),
        email: Yup.string()
            .email(`${t("signup:invalidEmail")}`)
            .required(`${t("signup:emailRequired")}`),
        emailConfirmation: Yup.string()
            .oneOf([Yup.ref('email'), null], `${t("signup:emailsMustMatch")}`)
            .required(`${t("signup:confirmYourEmail")}`),
        password: Yup.string()
            .min(6, `${t("signup:passwordMustHave6Digits")}`)
            .required(`${t("signup:passwordRequired")}`),
        phone: Yup.string()
            .required(`${t("signup:whatsAppRequired")}`)
            .test('isValidPhoneNumber', `${t("signup:invalidPhone")}`, value => isValidPhoneNumber(value))
            .test('isBrazilPhoneLength', `${t("signup:invalidPhone")}`, value =>
                !value.startsWith('+55') || (value.startsWith('+55') && value.replace(/[^0-9]/g, '').length === 13)),
        sexo: Yup.string()
            .required(`${t("signup:selectGender")}`)
    });

    const handleGoBack = () => {
        dispatch(setError(0))
        navigate("/user")
    }

    const handleSubmit = (values) => {
        const payload = {
            formData: values,
            mclid: mclid
        }
        dispatch(create(payload))
    }

    const handleTryAgain = () => {
        dispatch(setError(0))
        navigate("/user")
    }

    const handleForgotPasswordClick = () => {
        dispatch(setError(0))
        navigate(`/recover-password/user`);
    }

    return (
        <>
            <div className={'grid xl:grid-cols-2 h-screen content-center w-full'}>
                <div className={"hidden xl:block"}>
                    <SidePanel>
                        <img
                            src={userFemale1}
                            alt={"bg-user-female-1"}
                            className={"h-full w-full object-cover"}
                        />
                    </SidePanel>
                </div>
                <div className={'flex flex-col justify-center w-full'}>
                    <img
                        src={defaultTop}
                        alt={"bg-mobile-top-default"}
                        className={"absolute right-0 top-0 w-min lg:hidden -z-10"}
                    />
                    <div className={'w-full content-center'}>
                        <div className={'flex flex-col items-center text-xs p-2'}>
                            <span onClick={handleGoBack} className={"cursor-pointer"}>
                                <i className={'fa-regular fa-chevron-left'}/>
                                <span className={'font-semibold ml-1'}>
                                    {t("signup:goBack")}
                                </span>
                            </span>
                        </div>
                        <div className={'flex flex-col items-center'}>
                            <div className={'text-center'}>
                                <img
                                    alt={"logo"}
                                    className={"max-w-[320px]"}
                                    src={`https://cdn.mfitpersonal.com.br/assets/logo/logo-mfit-horizontal-azul.png`}/>
                                <span className={'text-xl font-semibold'}>
                                        {t("signup:trainer")}
                                    </span>
                            </div>
                            {
                                error === 0 &&
                                <div className={'pt-4 min-w-96 content-center px-3'}>
                                    <Formik
                                        initialValues={{
                                            fullName: '',
                                            email: '',
                                            emailConfirmation: '',
                                            password: '',
                                            phone: '',
                                            sexo: ''
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values) => {
                                            handleSubmit(values)
                                        }}
                                    >
                                        {({errors, touched}) => (
                                            <Form>
                                                <div className="mt-2">
                                                    <Field name="fullName" type="text"
                                                           className="custom-form"
                                                           placeholder={t("signup:fullName")}/>
                                                    {errors.fullName && touched.fullName &&
                                                        <ErrorMessageInput
                                                            className={'text-red-danger-500'}>{errors.fullName}</ErrorMessageInput>}
                                                </div>
                                                <div className="mt-2">
                                                    <Field name="email" type="text"
                                                           className="custom-form"
                                                           placeholder={t("signup:email")}/>
                                                    {errors.email && touched.email &&
                                                        <ErrorMessageInput
                                                            className={'text-red-danger-500'}>{errors.email}</ErrorMessageInput>}
                                                </div>
                                                <div className="mt-2">
                                                    <Field name="emailConfirmation" type="email"
                                                           className="custom-form"
                                                           placeholder={t("signup:emailConfirmation")}/>
                                                    {errors.emailConfirmation && touched.emailConfirmation &&
                                                        <ErrorMessageInput
                                                            className={'text-red-danger-500'}>{errors.emailConfirmation}</ErrorMessageInput>}
                                                </div>
                                                <div className="mt-2">
                                                    <Field name={"password"}>
                                                        {({
                                                              field,
                                                              form: {setFieldValue, setFieldTouched}
                                                          }) => (
                                                            <div>
                                                                <PasswordInput
                                                                    placeholder={t("signup:password")}
                                                                    value={field.value}
                                                                    onChange={(value) => setFieldValue(field.name, value)}
                                                                    onBlur={() => setFieldTouched(field.name, true)}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                    {errors.password && touched.password &&
                                                        <ErrorMessageInput
                                                            className={'text-red-danger-500'}>{errors.password}</ErrorMessageInput>}
                                                </div>
                                                <div className="mt-2">
                                                    <Field name={"phone"}>
                                                        {({field, form}) => (
                                                            <PhoneInput
                                                                fieldName="phone"
                                                                defaultCountry={userLanguageFormated}
                                                                forceDialCode={true}
                                                                value={field.value}
                                                                onChange={(value, e) => form.setFieldValue(field.name, e.inputValue)}
                                                                onBlur={() => form.setFieldTouched(field.name, true)}
                                                                inputStyle={{
                                                                    width: '90%',
                                                                    border: 'solid 1px #e5e7eb',
                                                                }}
                                                                buttonStyle={{
                                                                    border: 'solid 1px #e5e7eb',
                                                                    backgroundColor: 'white',
                                                                    borderRightStyle: 'none',
                                                                    width: '100%',
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.phone && touched.phone &&
                                                        <ErrorMessageInput
                                                            className={'text-red-danger-500'}>{errors.phone}</ErrorMessageInput>}
                                                </div>
                                                <div className="mt-2">
                                                    <Field
                                                        as="select"
                                                        name="sexo"
                                                        className="custom-form"
                                                    >
                                                        <option value="">{t("signup:gender")}</option>
                                                        <option value="m">{t("signup:male")}</option>
                                                        <option value="f">{t("signup:female")}</option>
                                                        <option value="m">{t("signup:other")}</option>
                                                    </Field>
                                                    {errors.sexo && touched.sexo &&
                                                        <ErrorMessageInput
                                                            className={'text-red-danger-500'}>{errors.sexo}</ErrorMessageInput>}
                                                </div>
                                                <div className={'mt-2'}>
                                                    <Button type="submit"
                                                            size={"btn"}
                                                            variant={"default"}
                                                            disabled={loading}>{t("signup:signUp")}
                                                    </Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            }
                            {error === 406 &&
                                <div className={'flex flex-col items-center justify-center gap-2 pt-2 px-4'}>
                                    <WarningMessage
                                        icon={"fa-regular fa-circle-exclamation text-yellow-warning-500 fa-3x"}
                                        title={`${t("signup:emailAlreadyInUse")}`}
                                        subtitle={`${t("signup:emailAlreadyInUseText")}`}
                                    />
                                    <div className={'flex flex-col gap-1 p-1 pt-1 justify-center content-center'}>
                                        <Button
                                            variant={"default"}
                                            className={"min-w-[300px]"}
                                            size={"btn"}
                                            onClick={handleTryAgain}>{t("signup:signin")}
                                        </Button>
                                        <Button
                                            size={"btn"}
                                            className={"min-w-[300px]"}
                                            variant={"outline-default"}
                                            onClick={handleForgotPasswordClick}>{t("signup:forgotPassword")}
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="flex flex-row justify-center pt-4 text-sm">
                            <a href="https://termosdeuso.mfitpersonal.com.br/"
                               rel="noreferrer"
                               target="_blank">
                                {t("signup:termsOfUser")}
                            </a>
                        </div>
                    </div>
                </div>
                <img
                    src={defaultBottom}
                    alt={'bg-mobile-bottom-default'}
                    className={"fixed bottom-0 left-0 w-min xl:hidden lg:block -z-10"}
                />
            </div>
        </>
    )
}

export default Signup;