import {useDispatch} from "react-redux";
import Cookies from 'js-cookie'
import {getMainDomain} from "../cookie/cookieUtils";
import {setUser} from "../../store/user/userReducer";

export const useLogout = () => {
    const dispatch = useDispatch();

    const eraseCookies = async () => {
        const allCookies = Cookies.get();

        await Object.keys(allCookies).forEach(cookieName => {
            if (cookieName !== "isApple") {
                Cookies.remove(cookieName, {path: '/'});
            }
        });
    }
    const logout = async () => {
        localStorage.clear();
        await eraseCookies()
        const mainDomain = getMainDomain();
        Cookies.remove('client_token', {path: '/', domain: `.${mainDomain}`});
        Cookies.remove("client_token");
        Cookies.remove('client_token', {path: '/'})
        Cookies.remove('user_token', {path: '/', domain: `.${mainDomain}`});
        Cookies.remove("user_token");
        Cookies.remove('user_token', {path: '/'})
        Cookies.remove("checkedMultipleUsers");
        Cookies.remove("updateCheck");
        dispatch(setUser(null))
    }
    return {logout};
}
