import {createSlice} from '@reduxjs/toolkit';

const INITIAL_STATE = {
    loading: false,
    authentication: 0,
    passwordRecovered: 0,
    mclid: null
}

export const slice = createSlice({
    name: "authentication",
    initialState: INITIAL_STATE,
    reducers: {
        setMclid(state, action) {
            state.mclid = action.payload;
        },
        recoverPassword(state) {
            state.loading = true;
        },
        setRecoveredPassword(state, action) {
            state.loading = false
            state.passwordRecovered = action.payload;
        },
        authenticate(state) {
            state.loading = true;
        },
        setAuthentication(state, action) {
            state.loading = false
            state.authentication = action.payload;
        },
        onError(state) {
            state.loading = false
        }
    }
})

export const {authenticate, setAuthentication, recoverPassword, setRecoveredPassword, setMclid, onError} = slice.actions;
export const authenticationReducer = slice.reducer;